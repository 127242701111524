<template>
    <div>
        <b-sidebar aria-labelledby="sidebar-no-header-title" no-header id="add_fixed_assets" backdrop style="direction:ltr" width="50rem" right :title="`اضافة اصول ثابتة`" shadow >
            <template #default="{ hide }">
                <div class="m-1 text-start" style="direction:rtl">
                    <div style="width:120px;margin-right:10px;background:#000;color:#FFF;border-radius:5px" class="btn-primary btn-sm text-center">
                    <span>اضافة اصول ثابتة</span>
                    </div>
                    <div @click="hide" id="closeaddpayment" style="width:100px;text-align:center;float:left;margin-left:5px;margin-top:-25px;cursor:pointer" class="btn-danger btn-sm closethis">
                    <span>{{$parent.lang.close}}</span>
                </div>
                </div>
                <v-divider></v-divider>
                <v-row :style="`direction:`+$parent.lang.dir" class="pa-2 m-2">
                    <v-col cols="12" md="5" sm="12">
                        <label>{{$parent.lang.namear}}</label>
                        <b-form-input class="inborder"
                            :label="$parent.lang.namear"
                            v-model="namear" 
                            :rules="fieldRules"
                        >{{ namear }}</b-form-input>
                    </v-col>
                    <v-col cols="12" md="5" sm="12">
                        <label>{{$parent.lang.nameen}}</label>
                        <b-form-input class="inborder"
                            :label="$parent.lang.nameen"
                            v-model="nameen" 
                            :rules="fieldRules"
                        >{{ nameen }}</b-form-input>
                    </v-col>
                    <v-col cols="12" md="2" sm="12">
                        <label>سعر الشراء</label>
                        <b-form-input class="inborder"
                            v-model="pprice" 
                            type="number"
                        >{{ pprice }}</b-form-input>
                    </v-col>
                    <v-col cols="12" md="2" sm="12">
                        <label>الكمية</label>
                        <b-form-input class="inborder"
                            v-model="qtty" 
                            type="number"
                        >{{ qtty }}</b-form-input>
                    </v-col>
                    <v-col cols="12" md="2" sm="12">
                        <label>نسبة الاهلاك</label>
                        <b-form-input class="inborder"
                            v-model="dep_percentage" 
                            type="number"
                        >{{ dep_percentage }}</b-form-input>
                    </v-col>
                    <v-col cols="12" md="4" sm="12">
                        <label>تاريخ الشراء</label>
                        <b-form-input class="inborder"
                            v-model="purchase_date" 
                            type="date"
                        >{{ purchase_date }}</b-form-input>
                    </v-col>
                    <v-col cols="12" md="4" sm="12">
                        <label>المجموعة</label>
                        <b-input-group>
                        <b-form-select class="selborder"
                            v-model="groupid"
                            :options="groups"
                        ></b-form-select>
                            <b-input-group-addon style="margin-right:-20px;margin-top:5px;">
                                <i class="fas fa-arrow-down"></i>
                            </b-input-group-addon>
                        </b-input-group>
                    </v-col>
                </v-row>
            </template>
            <template #footer="" class="shadow">
                    <div class="d-flex text-light align-items-center px-3 py-2">
                        <b-button type="button" @click="addPaytype();" variant="success" class="ma-2" style="width:100px;">حفظ</b-button>
                    </div>
            </template>
        </b-sidebar>
        <vue-snotify></vue-snotify>
    </div>
</template>

<script>
import axios from 'axios'
import {SnotifyPosition} from 'vue-snotify';
export default {
    data() {
        return {
            id: '', 
            namear: '', 
            nameen: '', 
            groupid: 1, 
            pprice: '', 
            purchase_date: '', 
            dep_percentage: '',
            qtty: 1,
            groups: [],
            fieldRules: []
        }
    },
    created() {
        this.getGroups();
    },
    methods: {
        restall(){
            this.id = '';
            this.namear = '';
            this.nameen = '';
            this.groupid = 1;
            this.pprice = '';
            this.qtty = 1;
            this.purchase_date = '';
            this.dep_percentage = '';
        },
        fillit(item){
            this.id = item.id;
            this.namear = item.namear;
            this.nameen = item.nameen;
            this.groupid = item.groupid;
            this.pprice = item.pprice;
            this.qtty = item.qtty;
            this.purchase_date = item.purchase_date;
            this.dep_percentage = item.dep_percentage;
        },
        getGroups(){
            const post = new FormData();
            post.append("type","getAssetsGroups")
            post.append("auth",this.$cookies.get(this.$COOKIEPhase))
            post.append("data[lang]",this.$parent.lang.langname);
    
            axios.post(
                this.$store.state.SAMCOTEC.r_path, post
            ).then((response) => {
                const res = response.data;
                this.groups = [];
                for(let i=0;i<res.results.data.length;i++){
                    this.groups.push({
                        text: res.results.data[i].namear,
                        value: res.results.data[i].id,
                    })
                }
            })
        },
        
        addPaytype(){
            let vald = true;
            let mess = '';
            if(this.namear == ''){
                vald = false;
                mess = 'يرجى ادخال اسم الاصل بالعربي';
            }
            if(this.namene == '' && vald){
                vald = false;
                mess = 'يرجى ادخال اسم الاصل بالانجليزي';
            }
            if(this.pprice == '' && vald){
                vald = false;
                mess = 'يرجى ادخال سعر الشراء';
            }
            if(this.purchase_date == '' && vald){
                vald = false;
                mess = 'يرجى ادخال تاريخ الشراء';
            }
            if(this.dep_percentage == '' && vald){
                vald = false;
                mess = 'يرجى ادخال نسبة الاهلاك';
            }
            if(this.qtty == '' && vald){
                vald = false;
                mess = 'يرجى ادخال الكمية';
            }

            if(vald == false){
                this.$snotify.error(mess, 'تنبيه', {
                    timeout: 1000000,
                    showProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    position: SnotifyPosition.centerCenter,
                    buttons: [
                        {
                            text: this.$parent.lang.close, 
                            action: (toast) => {
                                this.$snotify.remove(toast.id); 
                            } 
                        },
                    ]
                });
                return false;
            }
            const post = new FormData();
            post.append('type',"doaddFixedAssets");
            post.append('auth',this.$cookies.get(this.$store.state.COOKIEPhase));
            post.append('data[lang]',this.$cookies.get(this.$store.state.LangCooki));
            post.append('data[id]',this.id);
            post.append('data[namear]',this.namear);
            post.append('data[nameen]',this.nameen);
            post.append('data[groupid]',this.groupid);
            post.append('data[pprice]',this.pprice);
            post.append('data[qtty]',this.qtty);
            post.append('data[purchase_date]',this.purchase_date);
            post.append('data[dep_percentage]',this.dep_percentage);
            axios.post(
                this.$store.state.SAMCOTEC.r_path , post
            ).then((response) => {
                const res = response.data;
                // // console.log(res);
                if(typeof res.results.data !== 'undefined')
                {
                    let message = res.results.data;
                    this.$snotify.error(message, 'تنبيه', {
                        timeout: 1000000,
                        showProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        position: SnotifyPosition.centerCenter,
                        buttons: [
                            {
                                text: this.$parent.lang.close, 
                                action: (toast) => {
                                    this.$snotify.remove(toast.id); 
                                } 
                            },
                        ]
                    });
                    this.$parent.getFixedAsstes();
                    document.getElementById('closeaddpayment').click();
                }
            })
        }
    },
}
</script>